import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js'
import { Task } from '@lit/task';
import { services } from "src/services";
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { raiseFetchResponseError } from 'src/modules/helpers/response-helpers';

@customElement('ctx-content-preview')
export class CtxContentPreview extends LitElement {
  createRenderRoot() {
    return this;
  }

  static styles = [
    css`
    `
  ];

  @state() private _value: string = "";
  @state() htmlContent: string = "";

  get value(): string {
    return this._value;
  }

  set value(newValue: string) {
    this._value = newValue;
    this.requestUpdate();
  }

  private _markdownPreviewTask: Task = new Task(this, {
    task: async ([value], { signal }) => {
      var reponse = await services.management.markdown.preview(value).catch(raiseFetchResponseError);
      if(signal.aborted)
      {
        return this._value;
      }
    this.htmlContent = reponse.markup;
      return reponse.markup;
    },
    args: () => [this._value],
  });

  render() {
    return html`<div>
        ${this._markdownPreviewTask.render({
        initial: () => html``,
        pending: () => unsafeHTML(this.htmlContent),
        complete: () => html`${unsafeHTML(this.htmlContent)}`,
        error: (error) => html`<p>Oops, something went wrong: ${error}</p>`,
        })}
    </div>`;
  }
}