import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js'

@customElement('ctx-content-heading-select')
export class CtxContentHeadingSelect extends LitElement {
  static styles = [
    css`
        .flex {
            display: flex;
            gap: 4px;
        }

        .select-category {
            color: #4b5563;
            font-weight: 600;
            margin-bottom: 0.25rem;
        }
    `
  ];

  render() {
    return html`<ctx-content-select headingIcon="short_text" heading="Headings">
        <div class="select-category">
            Markup
        </div>
        <ctx-content-select-option value="# Heading 1">
            <div class="flex">
                <span>#</span>
                Heading 1
            </div>
        </ctx-content-select-option>
        <ctx-content-select-option value="## Heading 2">
            <div class="flex">
                <span>#</span>
                Heading 2
            </div>
        </ctx-content-select-option>
        <ctx-content-select-option value="### Heading 3">
            <div class="flex">
                <span>#</span>
                Heading 3
            </div>
        </ctx-content-select-option>
        <ctx-content-select-option value="#### Heading 4">
            <div class="flex">
                <span>#</span>
                Heading 4
            </div>
        </ctx-content-select-option>
        <ctx-content-select-option value="##### Heading 5">
            <div class="flex">
                <span>#</span>
                Heading 5
            </div>
        </ctx-content-select-option>
        <ctx-content-select-option value="###### Heading 6">
            <div class="flex">
                <span>#</span>
                Heading 6
            </div>
        </ctx-content-select-option>
        <hr />
        <div class="select-category">
            HTML
        </div>
        <ctx-content-select-option value="<h1>Heading 1</h1>">
            <div class="flex">
                <mwc-icon>code</mwc-icon>
                H1
            </div>
        </ctx-content-select-option>
        <ctx-content-select-option value="<h2>Heading 2</h2>">
            <div class="flex">
                <mwc-icon>code</mwc-icon>
                H2
            </div>
        </ctx-content-select-option>
        <ctx-content-select-option value="<h3>Heading 3</h3>">
            <div class="flex">
                <mwc-icon>code</mwc-icon>
                H3
            </div>
        </ctx-content-select-option>
        <ctx-content-select-option value="<h4>Heading 4</h4>">
            <div class="flex">
                <mwc-icon>code</mwc-icon>
                H4
            </div>
        </ctx-content-select-option>
        <ctx-content-select-option value="<h5>Heading 5</h5>">
            <div class="flex">
                <mwc-icon>code</mwc-icon>
                H5
            </div>
        </ctx-content-select-option>
        <ctx-content-select-option value="<h6>Heading 6</h6>">
            <div class="flex">
                <mwc-icon>code</mwc-icon>
                H6
            </div>
        </ctx-content-select-option>
    </ctx-content-select>`;
  }
}