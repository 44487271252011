import { LitElement, html, css, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js'

@customElement('ctx-content-select-option')
export class CtxContentSelectOption extends LitElement {
  static styles = [
    css`
        .flex {
          align-items: center;
          display: flex;
          gap: 0.25rem;
        }

        .option {
          cursor: pointer;
        }

        .option:hover {
          background-color: #f3f4f6;
          padding-inline: 0.25rem;
        } 
    `
  ];

  @property() displayIcon: string;
  @property() displayText: string;
  @property() value: string;

  private _selected = async () => {
    this.dispatchEvent(new CustomEvent("option-selected", { detail: {
      value: this.value
    }, bubbles: true, composed: true }));
  }

  render() {
    return html`<div class="option">
      ${this._renderDisplayContent()}
    </div>`;
  }

  private _renderDisplayContent() {
    return this.displayIcon || this.displayText ? html`<div class="flex" @click=${this._selected}>${this._renderDisplayIcon()} ${this.displayText}</div>` : html`<slot @click=${this._selected}></slot>`;
  }

  private _renderDisplayIcon() {
    return this.displayIcon ? html`<mwc-icon>${this.displayIcon}</mwc-icon>` : nothing;
  }
}