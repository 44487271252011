export class InsertEditorContentEvent extends Event {
    static readonly eventName = 'insert-content';
    onDisconnect?: () => void;
  
    public readonly content:string
    constructor(content:string) {
      super(InsertEditorContentEvent.eventName, {
        bubbles: true,
        composed: true,
        cancelable: false,
      });
      this.content = content;
    }
  }
  
  declare global {
    interface HTMLElementEventMap {
      [InsertEditorContentEvent.eventName]: InsertEditorContentEvent;
    }
  }